import {measurementSystemToUnit} from './Measurements';
import {
  HOME_GOOD_EURO_PILLOW_SHAM, HOME_GOOD_STANDARD_PILLOW_SHAM, HOME_GOOD_PLACEMAT, HOME_GOOD_COCKTAIL_NAPKIN,
  HOME_GOOD_DINNER_NAPKIN, HOME_GOOD_TEA_TOWEL, HOME_GOOD_THROW_BLANKET, HOME_GOOD_SQUARE_THROW_PILLOW,
  HOME_GOOD_LUMBAR_THROW_PILLOW, HOME_GOOD, FABRIC, WALLPAPER, HOME_GOOD_RECTANGULAR_TABLE_CLOTH,
  HOME_GOOD_ROUND_TABLE_CLOTH, HOME_GOOD_TABLE_RUNNER, HOME_GOOD_DUVET_COVER, HOME_GOOD_SHEET_SET,
  HOME_GOOD_CURTAIN_PANEL, FABRIC_ORGANIC_COTTON_KNIT_BRAVA, HOME_GOOD_LONG_LUMBAR_THROW_PILLOW,
  FABRIC_BELGIAN_LINEN, FABRIC_LINEN_COTTON_CANVAS, FABRIC_MINKY, FABRIC_CYPRESS_COTTON_BRAVA,
  FABRIC_PETAL_SIGNATURE_COTTON, FABRIC_MODERN_JERSEY, FABRIC_CELOSIA_VELVET, FABRIC_DOGWOOD_DENIM,
  FABRIC_LIGHTWEIGHT_COTTON_TWILL, HOME_GOOD_KNIFE_EDGED_LUMBAR_THROW_PILLOW, HOME_GOOD_KNIFE_EDGED_SQUARE_THROW_PILLOW,
  FABRIC_RECYCLED_CANVAS, HOME_GOOD_LONG_LUMBAR_THROW_PILLOW_SIZE_STANDARD_STYLE_KNIFE_EDGED, FABRIC_SPORT_LYCRA,
  FABRIC_CHIFFON, FABRIC_ORGANIC_SWEET_PEA_GAUZE, FABRIC_PERFORMANCE_PIQUE, FABRIC_ORGANIC_COTTON_SATEEN,
  FABRIC_COTTON_POPLIN_BRAVA, FABRIC_SATIN, FABRIC_COTTON_SPANDEX_JERSEY_BRAVA, FABRIC_POLY_CREPE_DE_CHINE, FABRIC_PERFORMANCE_VELVET,
  FABRIC_COTTON_LAWN_APPAREL, FABRIC_PERFORMANCE_LINEN, SOLID, FABRIC_FLEECE, FABRIC_POLARTEC_FLEECE, FABRIC_PERENNIAL_SATEEN_GRAND,
  FABRIC_LONGLEAF_SATEEN_GRAND, FABRIC_SEERSUCKER, FABRIC_COTTON_SILK, FABRIC_ESSEX_LINEN, FABRIC_SPORT_STRETCH_WOVEN, HOME_GOOD_WALL_HANGING
} from './Codes';
import {REGISTERED_TRADEMARK, TRADEMARK} from './Common';

// These are the default widths of Basic Cotton Ultra Fat Quarters which are the ones displayed in
// the Quicklook. We need to change them if we modify the default fabric.
export const ALLOW_QUICKLOOK = false;

// Pagination
export const DEFAULT_NUM_RESULTS_PER_PAGE = 84;

export const DEFAULT_FABRIC_WIDTH = {
  METRIC: 53,
  IMPERIAL: 21
};

export const SWATCH_IMPERIAL_MEASURES = 8;
export const SWATCH_METRIC_MEASURES = 20;

export const COLOR_MAP_ID = '509390';

export const ROUTING_PATH_FABRIC = 'fabric.page';
export const ROUTING_PATH_SOLID = 'solid.page';
export const ROUTING_PATH_WALLPAPER = 'wallpaper.page';

export const FABRIC_IMAGE_WIDTH = '300';
export const FABRIC_IMAGE_HEIGHT = '300';
export const FABRIC_LANDING_IMAGE_SIZE_MAIN = 650;
export const FABRIC_LANDING_IMAGE_ZOOM_SIZE_MAIN = 1024;

export const WALLPAPER_METRIC_10M = '10M';
export const WALLPAPER_METRIC_60_96_x_400 = '60_96_x_400';
export const WALLPAPER_METRIC_60_96_x_100 = '60_96_x_100';
export const WALLPAPER_METRIC_TEST_SWATCH_60_96_x_30 = 'WALLPAPER_METRIC_TEST_SWATCH_60_96_x_30';
export const WALLPAPER_IMPERIAL_2_x_27 = '2_x_27';
export const WALLPAPER_IMPERIAL_2_x_12 = '2_x_12';
export const WALLPAPER_IMPERIAL_2_x_3 = '2_x_3';
export const WALLPAPER_IMPERIAL_TEST_SWATCH_2_x_1 = 'WALLPAPER_IMPERIAL_TEST_SWATCH_2_x_1';

export const METER_ABBREVIATION = 'm';
export const YARD_ABBREVIATION = 'yd';

export const PRODUCT_NAME_TO_KEY_PRODUCT_MAP = {
  [FABRIC]: 'fabric',
  [WALLPAPER]: 'wallpaper',
  [HOME_GOOD]: 'home-good',
  [HOME_GOOD_SQUARE_THROW_PILLOW]: 'squareThrowPillows',
  [HOME_GOOD_RECTANGULAR_TABLE_CLOTH]: 'rectangularTablecloths',
  [HOME_GOOD_ROUND_TABLE_CLOTH]: 'roundTablecloths',
  [HOME_GOOD_TABLE_RUNNER]: 'tableRunners',
  [HOME_GOOD_PLACEMAT]: 'placemats',
  [HOME_GOOD_COCKTAIL_NAPKIN]: 'cocktailNapkins',
  [HOME_GOOD_DINNER_NAPKIN]: 'dinnerNapkins',
  [HOME_GOOD_TEA_TOWEL]: 'teaTowels',
  [HOME_GOOD_DUVET_COVER]: 'duvetCovers',
  [HOME_GOOD_SHEET_SET]: 'sheetSets',
  [HOME_GOOD_STANDARD_PILLOW_SHAM]: 'standardPillowShams',
  [HOME_GOOD_EURO_PILLOW_SHAM]: 'euroPillowShams',
  [HOME_GOOD_LUMBAR_THROW_PILLOW]: 'lumbarThrowPillows',
  [HOME_GOOD_THROW_BLANKET]: 'throwBlankets',
  [HOME_GOOD_CURTAIN_PANEL]: 'curtains',
  [HOME_GOOD_LONG_LUMBAR_THROW_PILLOW]: 'longLumbarThrowPillow',
  [HOME_GOOD_WALL_HANGING]: 'wallHanging'
};

export const FABRIC_URL_CODE_MAP = {
  'belgischer-leinenstoff': FABRIC_BELGIAN_LINEN,
  'belgian-linen': FABRIC_BELGIAN_LINEN,
  'lino-belga': FABRIC_BELGIAN_LINEN,
  'lin-naturel-de-belgique': FABRIC_BELGIAN_LINEN,
  'leinen-baumwoll-canvas': FABRIC_LINEN_COTTON_CANVAS,
  'linen-cotton-canvas': FABRIC_LINEN_COTTON_CANVAS,
  'loneta-de-algodon-y-lino': FABRIC_LINEN_COTTON_CANVAS,
  'toile-coton-lin': FABRIC_LINEN_COTTON_CANVAS,
  'minky': FABRIC_MINKY,
  'bio-baumwoll-interlock-jersey': FABRIC_ORGANIC_COTTON_KNIT_BRAVA,
  'organic-cotton-knit': FABRIC_ORGANIC_COTTON_KNIT_BRAVA,
  'punto-de-algodon-organico': FABRIC_ORGANIC_COTTON_KNIT_BRAVA,
  'maille-biologique': FABRIC_ORGANIC_COTTON_KNIT_BRAVA,
  'panama-baumwoll-canvas': FABRIC_CYPRESS_COTTON_BRAVA,
  'cypress-cotton-canvas': FABRIC_CYPRESS_COTTON_BRAVA,
  'loneta-de-algodon-tejida': FABRIC_CYPRESS_COTTON_BRAVA,
  'toile-coton': FABRIC_CYPRESS_COTTON_BRAVA,
  'baumwollstoff-klassik': FABRIC_PETAL_SIGNATURE_COTTON,
  'petal-signature-cotton': FABRIC_PETAL_SIGNATURE_COTTON,
  'tela-de-algodon-clasico': FABRIC_PETAL_SIGNATURE_COTTON,
  'tissu-coton-classique': FABRIC_PETAL_SIGNATURE_COTTON,
  'synthetik-jersey': FABRIC_MODERN_JERSEY,
  'modern-jersey': FABRIC_MODERN_JERSEY,
  'punto-de-jersey': FABRIC_MODERN_JERSEY,
  'maille-moderne': FABRIC_MODERN_JERSEY,
  'samt': FABRIC_CELOSIA_VELVET,
  'celosia-velvet': FABRIC_CELOSIA_VELVET,
  'terciopelo': FABRIC_CELOSIA_VELVET,
  'velours': FABRIC_CELOSIA_VELVET,
  'jeansstoff': FABRIC_DOGWOOD_DENIM,
  'dogwood-denim': FABRIC_DOGWOOD_DENIM,
  'jean': FABRIC_DOGWOOD_DENIM,
  'denim': FABRIC_DOGWOOD_DENIM,
  'leichter-baumwoll-twill': FABRIC_LIGHTWEIGHT_COTTON_TWILL,
  'lightweight-cotton-twill': FABRIC_LIGHTWEIGHT_COTTON_TWILL,
  'sarga-de-algodon-ligera': FABRIC_LIGHTWEIGHT_COTTON_TWILL,
  'coton-serge-leger': FABRIC_LIGHTWEIGHT_COTTON_TWILL,
  'sport-lycra': FABRIC_SPORT_LYCRA,
  'lycra-deportiva': FABRIC_SPORT_LYCRA,
  'lycra-sport': FABRIC_SPORT_LYCRA,
  'chiffon': FABRIC_CHIFFON,
  'gasa': FABRIC_CHIFFON,
  'mousseline-de-soie': FABRIC_CHIFFON,
  'recycled-canvas': FABRIC_RECYCLED_CANVAS,
  'recycelter-canvas': FABRIC_RECYCLED_CANVAS,
  'toile-recyclee': FABRIC_RECYCLED_CANVAS,
  'loneta-reciclada': FABRIC_RECYCLED_CANVAS,
  'organic-sweet-pea-gauze': FABRIC_ORGANIC_SWEET_PEA_GAUZE,
  'bio-musselin': FABRIC_ORGANIC_SWEET_PEA_GAUZE,
  'gaze-biologique': FABRIC_ORGANIC_SWEET_PEA_GAUZE,
  'gasa-organica': FABRIC_ORGANIC_SWEET_PEA_GAUZE,
  'performance-pique': FABRIC_PERFORMANCE_PIQUE,
  'sport-pique': FABRIC_PERFORMANCE_PIQUE,
  'organic-cotton-sateen': FABRIC_ORGANIC_COTTON_SATEEN,
  'bio-baumwoll-satin': FABRIC_ORGANIC_COTTON_SATEEN,
  'coton-biologique-satine': FABRIC_ORGANIC_COTTON_SATEEN,
  'saten-de-algodon-organico': FABRIC_ORGANIC_COTTON_SATEEN,
  'cotton-poplin': FABRIC_COTTON_POPLIN_BRAVA,
  'baumwoll-popeline': FABRIC_COTTON_POPLIN_BRAVA,
  'popeline-de-coton': FABRIC_COTTON_POPLIN_BRAVA,
  'popelin-de-algodon': FABRIC_COTTON_POPLIN_BRAVA,
  'satin': FABRIC_SATIN,
  'saten': FABRIC_SATIN,
  'cotton-spandex-jersey': FABRIC_COTTON_SPANDEX_JERSEY_BRAVA,
  'baumwoll-spandex-jersey': FABRIC_COTTON_SPANDEX_JERSEY_BRAVA,
  'jersey-coton-spandex': FABRIC_COTTON_SPANDEX_JERSEY_BRAVA,
  'punto-de-jersey-con-elastano-premium': FABRIC_COTTON_SPANDEX_JERSEY_BRAVA,
  'poly-crepe-de-chine': FABRIC_POLY_CREPE_DE_CHINE,
  'seidenimitat-crepe-de-chine': FABRIC_POLY_CREPE_DE_CHINE,
  'crepe-de-chine-polyester': FABRIC_POLY_CREPE_DE_CHINE,
  'crepe-de-poliester': FABRIC_POLY_CREPE_DE_CHINE,
  'matter-samt': FABRIC_PERFORMANCE_VELVET,
  'performance-velvet': FABRIC_PERFORMANCE_VELVET,
  'terciopelo-de-rendimiento': FABRIC_PERFORMANCE_VELVET,
  'velours-d-ameublement': FABRIC_PERFORMANCE_VELVET,
  'baumwollbatist': FABRIC_COTTON_LAWN_APPAREL,
  'cotton-lawn': FABRIC_COTTON_LAWN_APPAREL,
  'batista-de-algodon': FABRIC_COTTON_LAWN_APPAREL,
  'batiste-de-coton': FABRIC_COTTON_LAWN_APPAREL,
  'synthetik-leinenstoff': FABRIC_PERFORMANCE_LINEN,
  'performance-linen': FABRIC_PERFORMANCE_LINEN,
  'el-lino-de-rendimiento': FABRIC_PERFORMANCE_LINEN,
  'tissu-lin-dameublement': FABRIC_PERFORMANCE_LINEN,
  'fleece': FABRIC_FLEECE,
  'polar': FABRIC_FLEECE,
  'polaire': FABRIC_FLEECE,
  'polartec-fleece': FABRIC_POLARTEC_FLEECE,
  'polartec-polar': FABRIC_POLARTEC_FLEECE,
  'polartec-polaire': FABRIC_POLARTEC_FLEECE,
  'wide-cotton-sateen-3-5-oz': FABRIC_PERENNIAL_SATEEN_GRAND,
  'extrabreiter-leichter-baumwollsatin': FABRIC_PERENNIAL_SATEEN_GRAND,
  'saten-ligero-grand': FABRIC_PERENNIAL_SATEEN_GRAND,
  'satin-leger-grand-format': FABRIC_PERENNIAL_SATEEN_GRAND,
  'wide-cotton-sateen-6-5-oz': FABRIC_LONGLEAF_SATEEN_GRAND,
  'extrabreiter-schwerer-baumwollsatin': FABRIC_LONGLEAF_SATEEN_GRAND,
  'saten-de-alto-gramaje-grand': FABRIC_LONGLEAF_SATEEN_GRAND,
  'satin-epais-grand-format': FABRIC_LONGLEAF_SATEEN_GRAND,
  'seersucker': FABRIC_SEERSUCKER,
  'cotton-silk': FABRIC_COTTON_SILK,
  'essex-linen': FABRIC_ESSEX_LINEN,
  'sport-stretch-woven': FABRIC_SPORT_STRETCH_WOVEN
};

export const hasTrademark = {
  FABRIC_BELGIAN_LINEN: TRADEMARK,
  SOLID_FABRIC_PETAL: REGISTERED_TRADEMARK,
};

export const hasThreeDetailImages = [
  FABRIC_PERFORMANCE_PIQUE
];

export const hasFiveDetailImages = [
  FABRIC_POLARTEC_FLEECE
];

export const noSizeOption = [
  HOME_GOOD_PLACEMAT, HOME_GOOD_COCKTAIL_NAPKIN, HOME_GOOD_DINNER_NAPKIN, HOME_GOOD_TEA_TOWEL, HOME_GOOD_THROW_BLANKET, SOLID
];
const COLOR_MAPS_PRODUCTS_IDS = [509390, 10662139];

export const hasNoSizeOption = (productType) => noSizeOption.includes(productType);

export const isColorMapProduct = (designId) => COLOR_MAPS_PRODUCTS_IDS.includes(designId);

export const fabricSizeWithMeasurementUnit = (fabricSizeName, measurementSystem) => {
  const measurementUnit = measurementSystemToUnit[measurementSystem];
  const nameReady = fabricSizeName === measurementUnit || fabricSizeName.includes(measurementUnit);

  return nameReady ? fabricSizeName : `${fabricSizeName}_${measurementUnit}`;
};

export const IS_HOME_GOOD = (productTypeAbbreviated) => productTypeAbbreviated === HOME_GOOD;
export const IS_FABRIC = (productTypeAbbreviated) => productTypeAbbreviated === FABRIC;
export const IS_WALLPAPER = (productTypeAbbreviated) => productTypeAbbreviated === WALLPAPER;
export const IS_SOLID = (productTypeAbbreviated) => productTypeAbbreviated === SOLID;

export const PRODUCT_STYLE = 'style';
export const CHOOSE_A_STYLE = 'Choose a style';
export const PRODUCT_SIZE = 'size';
export const CHOOSE_A_SIZE = 'Choose a size';
export const PRODUCT_ADDITION = 'addition';

export const ALL_SIZES = 'ALL_SIZES';
// TODO SP-5679: Clean up once stacked image formula includes ALL_SIZE
export const STACKED_LIFESTYLE = 'stacked_lifestyle';

export const fabricCertificateImage = (substrate) => [FABRIC_BELGIAN_LINEN, FABRIC_RECYCLED_CANVAS].includes(substrate);

// TODO SP-5926: Can be removed once home goods and images for flanged pillows are deactivated
export const pillowsWithoutFlanged = [HOME_GOOD_LUMBAR_THROW_PILLOW, HOME_GOOD_SQUARE_THROW_PILLOW, HOME_GOOD_LONG_LUMBAR_THROW_PILLOW];
export const pillowWithoutFlangedOption = (productType) => pillowsWithoutFlanged.includes(productType);

// TODO SP-5927: Can be removed once flanged style option is re-activated or removed completely
export const flangedPillowRedirection = {
  HOME_GOOD_FLANGED_SQUARE_THROW_PILLOW: HOME_GOOD_KNIFE_EDGED_SQUARE_THROW_PILLOW,
  HOME_GOOD_FLANGED_LUMBAR_THROW_PILLOW: HOME_GOOD_KNIFE_EDGED_LUMBAR_THROW_PILLOW,
  HOME_GOOD_LONG_LUMBAR_THROW_PILLOW_SIZE_STANDARD_STYLE_FLANGED: HOME_GOOD_LONG_LUMBAR_THROW_PILLOW_SIZE_STANDARD_STYLE_KNIFE_EDGED
};

export const FABRIC_CAROUSEL_LINK_INDEX = [2, 3, 4];
export const FABRIC_CAROUSEL_LINK_TYPES = [HOME_GOOD_SQUARE_THROW_PILLOW, HOME_GOOD_THROW_BLANKET, HOME_GOOD_DUVET_COVER, HOME_GOOD_TEA_TOWEL];

export const EXPERIMENTAL_PRODUCT_TYPES = [WALLPAPER];

// Returns true if the user has an experimental cookie AND if the product type is already being handled by the new PDP
export const IS_EXPERIMENTAL_PRODUCT = (productTypeAbbreviated) =>
  EXPERIMENTAL_PRODUCT_TYPES.includes(productTypeAbbreviated);

