/* eslint-disable max-lines */
// Shared functions for mapping state to common props for containers.
import Cookies from 'js-cookie';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import {Store} from 'redux';

import browserHistoryWrapper from '../components/Reusable/LinkWrapper/browserHistoryWrapper';
import {
  FAT_QUARTER,
  WALLPAPER,
  PILLOW,
  TABLECLOTH,
  TABLERUNNER,
  DUVETCOVER,
  SHEETSET,
  PLACEMAT,
  NAPKIN,
  TEATOWEL,
  CURTAIN,
  BLANKET,
  KNIFE_EDGED,
  FABRIC,
  FABRIC_PETAL_SIGNATURE_COTTON,
  UNSET_FLAG,
  WALLHANGING,
  WALLPAPER_GRASSCLOTH,
  DEFAULT_FABRIC
} from '../constants/Codes';
import {FABRIC_CODE_COOKIE_NAME, WALLPAPER_CODE_COOKIE_NAME} from '../constants/Cookies';
import {MeasurementSystem} from '../constants/Measurements';
import {FABRIC_PARAM, SIZE, FABRIC_SIZE} from '../constants/Parameters';
import {USD} from '../constants/Payment';
import {
  fabricSizeWithMeasurementUnit, flangedPillowRedirection
} from '../constants/Products';
import selectCountries from '../entities/pageSetup/countries/selectors/selectCountries';
import {Design, isDesignResponse} from '../shapes/design';
import {FabricOrderItem, isOrderItemType, OrderItemTypes, WallpaperOrderItem} from '../shapes/orderitems';
import {UserState} from '../shapes/user';
import {LocationQuery, State} from '../store/initialState';

import {setSecureCookie} from './cookies';
import {isColorMap, isGrandFabric} from './fabrics';
import {adjustCurrencyParams} from './preferenceHelpers';
import {upsertUrlQuery} from './url';
import {
  isNotUndefined, isValidMeasurementSystem, isValidCurrency, isValidCountry, isValidSubstrateCookie
} from './validation';


const queryFromState = (state: State): LocationQuery => (
  state.routing.locationBeforeTransitions.query
);

const measurementSystemFromQueryOrPreferences = (
  queryMeasurementSystem: MeasurementSystem | undefined,
  preferredMeasurementSystem: MeasurementSystem
): MeasurementSystem => (
  isValidMeasurementSystem(queryMeasurementSystem) ?
    queryMeasurementSystem.toUpperCase() as MeasurementSystem:
    preferredMeasurementSystem
);

const adjustedPreferences = (
  potentialCountry: string,
  potentialCurrency: string,
  potentialMeasurementSystem: MeasurementSystem
): Pick<UserState['preferences'], 'country' | 'currency' | 'measurement_system'> => ({
  country: potentialCountry,
  currency: potentialCurrency,
  measurement_system: potentialMeasurementSystem,
  ...adjustCurrencyParams(potentialCountry, potentialCurrency),
});

const getOrderItem = <T extends OrderItemTypes>(
  pending: State['carts']['pending'],
  orderItemId: number
): T | undefined => {
  const {order_items} = pending;

  if (isUndefined(order_items) || isEmpty(order_items)) {
    return;
  }

  return order_items.find((item): item is T => isOrderItemType(item) && item.id === orderItemId);
};

// Override UserPreferences from state with values from corresponding query params if present and valid.
export const queryParamsOverrideUserPreferences = (state: State): {
  country: string;
  currency: string;
  // TODO: SP-10081 Change this to "measurement_system" so we can use Pick on the UserState['preferences'] type.
  measurementSystem: MeasurementSystem;
  userPreferencesOverridden: boolean;
} => {
  const {
    routing: {locationBeforeTransitions: {query}},
    user: {
      preferences: {
        country: preferredCountry,
        currency: preferredCurrency,
        measurement_system: preferredMeasurementSystem,
      },
    },
  } = state;

  // If there are no query params, default to user preferences.
  if (isEmpty(query)) {
    return {
      country: preferredCountry,
      currency: preferredCurrency,
      measurementSystem: preferredMeasurementSystem,
      userPreferencesOverridden: false,
    };
  }

  const {
    country: queryCountry,
    measurement_system: queryMeasurementSystem
  } = query;

  const potentialCountry = isValidCountry(selectCountries(state), queryCountry) ?
    queryCountry.toUpperCase() :
    preferredCountry;
  // Ignoring all currencies coming from URL except the USD (SP-19571)
  const potentialCurrency = USD;
  const potentialMeasurementSystem = measurementSystemFromQueryOrPreferences(
    queryMeasurementSystem,
    preferredMeasurementSystem,
  );
  const {
    country: adjustedCountry,
    currency: adjustedCurrency,
    measurement_system: adjustedMeasurementSystem
  } = adjustedPreferences(potentialCountry, potentialCurrency, potentialMeasurementSystem);
  // Return if preferences are overriden by params so that receiving containers can respond accordingly.
  const userPreferencesOverridden = adjustedCountry !== preferredCountry ||
    adjustedMeasurementSystem !== preferredMeasurementSystem ||
    adjustedCurrency !== preferredCurrency;

  return {
    userPreferencesOverridden,
    country: adjustedCountry,
    currency: adjustedCurrency,
    measurementSystem: adjustedMeasurementSystem,
  };
};

const getPdpSelectedSubstratePropsForFabric = (state: State) => {
  const {
    design,
    fabrics,
    routing: {locationBeforeTransitions: {query: {fabric}}},
  } = state;

  const fabricUpper = `FABRIC_${fabric?.toUpperCase()}`;

  return {
    cookieName: FABRIC_CODE_COOKIE_NAME,
    defaultSubstrate: fabrics.default,
    queryCode: (!isUndefined(fabric) && fabrics.fabrics[fabricUpper]) ? fabricUpper : undefined,
    recommendedFabric: isDesignResponse(design) ? design.recommended_fabric : undefined,
  };
};

const getPdpSelectedSubstratePropsForWallpaper = (state: State) => {
  const {
    routing: {locationBeforeTransitions: {query: {fabric}}},
    wallpapers,
  } = state;

  return {
    cookieName: WALLPAPER_CODE_COOKIE_NAME,
    defaultSubstrate: wallpapers.default,
    queryCode: (!isUndefined(fabric) && wallpapers.wallpapers[fabric?.toUpperCase()]) ? fabric?.toUpperCase() : undefined,
    recommendedFabric: undefined,
  };
};

export const pdpSelectedSubstrate = (state: State, substrateType: string, wasRecommendedSubstrateChanged?: boolean): string | undefined => {
  const {user: {cookies}, design: {id}} = state;
  const isFabric = substrateType === FABRIC;
  const {cookieName, defaultSubstrate, queryCode, recommendedFabric} = isFabric ?
    getPdpSelectedSubstratePropsForFabric(state) :
    getPdpSelectedSubstratePropsForWallpaper(state);
  const recommendedFabricButNotPetal = recommendedFabric !== FABRIC_PETAL_SIGNATURE_COTTON ?
    recommendedFabric :
    undefined;
  const substrateCookie = process.env.REACT_APP_IS_SERVER && process.env.REACT_APP_IS_SERVER !== 'undefined' ?
    cookies[cookieName] :
    Cookies.get(cookieName);
  let validatedSubstrateCookie = isValidSubstrateCookie(substrateCookie) ? substrateCookie : undefined;
  const cookieError = !isUndefined(validatedSubstrateCookie) && !validatedSubstrateCookie.startsWith(isFabric ? 'FABRIC_' : 'WALLPAPER_');

  if (cookieError) {
    validatedSubstrateCookie = defaultSubstrate;
    setSecureCookie(cookieName, validatedSubstrateCookie);
  }

  if (wasRecommendedSubstrateChanged) {
    return queryCode ?? validatedSubstrateCookie ?? defaultSubstrate;
  }

  if (isColorMap(id) && (isGrandFabric(validatedSubstrateCookie) || isGrandFabric(queryCode))) {
    return defaultSubstrate ?? DEFAULT_FABRIC;
  }

  return queryCode ?? recommendedFabricButNotPetal ?? validatedSubstrateCookie ?? defaultSubstrate;
};

const genericHomeGoods = [
  BLANKET, CURTAIN, DUVETCOVER, NAPKIN, PILLOW, PLACEMAT,
  SHEETSET, TABLECLOTH, TABLERUNNER, TEATOWEL, WALLHANGING,
] as const;

export const deriveHomeGoodTypeGeneric = (
  homeGoodType: string | undefined
): typeof genericHomeGoods[number] | undefined => {
  if (isUndefined(homeGoodType)) {
    return;
  }

  for (const homeGood of genericHomeGoods) {
    if (homeGoodType.includes(homeGood)) {
      return homeGood;
    }
  }

  console.warn('No matching home good type to derive a generic home good type');
};

// Deprecated use selectedHomeGoodFabric should be used for new code
export const pdpSelectedHomeGoodFabric = (state: State): string | undefined => {
  const {
    addToCart: {
      allowed_fabrics: {
        default: defaultSubstrate,
        fabrics = []
      } = {}
    },
    routing: {locationBeforeTransitions: {query: {fabric}}},
    user: {cookies}
  } = state;
  const fabricCookie = process.env.REACT_APP_IS_SERVER ?
    cookies[FABRIC_CODE_COOKIE_NAME] :
    Cookies.get(FABRIC_CODE_COOKIE_NAME);
  const queryCode = !isUndefined(fabric) ?
    `FABRIC_${fabric.toUpperCase()}` :
    undefined;

  const fabricStringArray = fabrics.map((fabricType) => Object.keys(fabricType)).flat();

  if (!isUndefined(queryCode) && fabricStringArray.includes(queryCode)) {
    return queryCode;
  }

  if (!isUndefined(fabricCookie) && fabricStringArray.includes(fabricCookie)) {
    return fabricCookie;
  }

  return defaultSubstrate;
};

// Replace pdpSelectedHomeGoodFabric with below code after through testing
export const selectedHomeGoodFabric = (state: State): string | undefined => {
  const {
    addToCart: {
      allowed_fabrics: {fabrics = []} = {},
      fabric_code,
    },
    routing: {locationBeforeTransitions: {query: {fabric: queryFabric}}},
    user: {cookies},
  } = state;
  const cookieFabric = process.env.REACT_APP_IS_SERVER ?
    cookies[FABRIC_CODE_COOKIE_NAME] :
    Cookies.get(FABRIC_CODE_COOKIE_NAME);
  const orderItem = orderItemFromParams(state);
  let orderItemFabricCode;

  if (isNotUndefined(orderItem?.fabric)) {
    orderItemFabricCode = orderItem?.fabric.code;
  }
  const currentFabric = !isUndefined(queryFabric) ?
    `FABRIC_${queryFabric.toUpperCase()}` :
    orderItemFabricCode ? orderItemFabricCode : cookieFabric;

  const fabricStringArray = fabrics.map((fabricType) => Object.keys(fabricType)).flat();

  if (isUndefined(currentFabric) || (!isEmpty(fabrics) && !fabricStringArray.includes(currentFabric))) {
    return fabric_code;
  }

  return currentFabric;
};

// TODO SP-5927: Can be removed once flanged style option is re-activated or removed completely (incl. usage of this function in HomeGoodContainer)
export const pdpSelectedHomeGoodSize = (
  queryParams: LocationQuery,
  currentPath: string,
  currentOrigin: string,
): string | undefined => {
  const {size} = queryParams;

  if (isUndefined(size)) {
    return;
  }

  if (Object.keys(flangedPillowRedirection).includes(size)) {
    const redirection = flangedPillowRedirection[size as keyof typeof flangedPillowRedirection];
    const params = {
      [SIZE]: redirection,
    };
    const urlQuery = upsertUrlQuery(currentPath, params, currentOrigin);

    browserHistoryWrapper.push(urlQuery);

    return redirection;
  }
};

// if there is fabric data try to default to fat quarter first
export const defaultSize = (measurementSystem: MeasurementSystem): string =>
  fabricSizeWithMeasurementUnit(FAT_QUARTER, measurementSystem);

// Determine a fabric size code based on available query params and/or user preferences, with query params given
// higher priority.
// TODO: SP-10081 Type this properly
export const sizeFromParamsOrPreferences = (state: State, substrateCode: any) => {
  const {routing: {locationBeforeTransitions: {query: {order_item_id}}}} = state;
  const query = queryFromState(state);
  // Determines MeasurementSystem by comparing current user preferences query parameters (prioritized), and returns updated preferences
  const preferredMeasurementSystem = state.user.preferences.measurement_system;
  const potentialMeasurementSystem = measurementSystemFromQueryOrPreferences(query.measurement_system, preferredMeasurementSystem);
  const {measurement_system: measurementSystem} = adjustedPreferences(state.user.preferences.country, state.user.preferences.currency, potentialMeasurementSystem);

  const substratePricingResponse = get(state.addToCart, 'pricing');

  if (isNotUndefined(substratePricingResponse)) {
    const querySize = query[FABRIC_SIZE] ? query[FABRIC_SIZE] : query[SIZE];
    const substrate = !isEmpty(substrateCode) ? substrateCode : query[FABRIC_PARAM];
    const isWallpaper = substrate && substrate.toLowerCase().includes(WALLPAPER.toLowerCase());

    if (isNotUndefined(querySize)) {
      const substrateSizeFromQuery = isWallpaper ? querySize :
        fabricSizeWithMeasurementUnit(querySize.toUpperCase(), measurementSystem);

      if (isNotUndefined(substratePricingResponse[substrateSizeFromQuery])) {
        return substrateSizeFromQuery;
      }
    }

    if (!isUndefined(order_item_id)) {
      const orderItem = getOrderItem<FabricOrderItem | WallpaperOrderItem>(state.carts.pending, parseInt(order_item_id, 10));
      const size = orderItem?.fabric.size;

      if (!isUndefined(size) && !isUndefined(substratePricingResponse[size])) {
        // works for both fabrics and wallpaper
        return size;
      }
    }
  }

  const defaultSizeFromResponse = get(state.addToCart, 'default_size');

  return isNotUndefined(defaultSizeFromResponse) ? defaultSizeFromResponse : defaultSize(measurementSystem);
};

export const getFabricSize = (state: State, substrateCode: any) => {
  const {addToCart} = state;
  const productSizeFromAddToCart = addToCart.productSize;
  const sizeFromParams = sizeFromParamsOrPreferences(state, substrateCode);
  const initialSize = productSizeFromAddToCart || sizeFromParams;

  if (state.fabrics && state.fabrics.fabrics) {
    const sizingMap = get(state.fabrics.fabrics, `${substrateCode}.sizing`, {});
    const sizingMapKeys = Object.keys(sizingMap);

    // substrate is updated, but pricing data is still loading,
    // so we don't have this size available for selected fabric and we need to take available size
    if (!sizingMapKeys.includes(initialSize) && sizingMapKeys.length) {
      return Object.keys(sizingMap)[0];
    }
  }

  return initialSize;
};

export const productSizeHomeGood = (
  state: State,
  query: LocationQuery,
  homeGoodTypeGeneric: string | undefined
): string | undefined => {
  const {
    addToCart: {
      pricing,
      productSize,
    },
  } = state;
  const {size} = query;

  if (!isUndefined(productSize) && !isUndefined(pricing) && pricing[productSize]) {
    return productSize;
  }

  const prioritizedSizeChoices: string[] = [];

  if (!isUndefined(size)) {
    prioritizedSizeChoices.push(size);
  }

  if (!isUndefined(pricing)) {
    const pricingKeys = Object.keys(pricing);

    switch (homeGoodTypeGeneric) {
      case PILLOW: {
        const knifeEdgePillow = pricingKeys.find((pillow) => pillow.includes(KNIFE_EDGED));

        if (!isUndefined(knifeEdgePillow)) {
          prioritizedSizeChoices.push(knifeEdgePillow);
        }

        break;
      }
    }

    prioritizedSizeChoices.push(pricingKeys[0]);
  }

  return prioritizedSizeChoices.shift();
};

// TODO: SP-10081 Type this properly
export const getAfterpayAvailability = (state: any, productSize = 'undefined') => {
  const afterpayAvailable = state?.afterpay?.available;
  const afterpayBasic = afterpayAvailable ? {
    available: state.afterpay.available, // availability in the country
    eligible: state.afterpay.eligible, // availability on product/cart, also reflects too long turn time
    currencyValid: state.afterpay.currency_valid, // is it an accepted currency?
    showAfterpay: state.afterpay.show_afterpay, // combined available && currencyValid && eligible
    config: {
      currency: state.afterpay.config.currency,
      maxValue: state.afterpay.config.max_value,
      minValue: state.afterpay.config.min_value
    }
  } : {
    available: false
  };

  const showInstallments = afterpayAvailable && afterpayBasic.showAfterpay && isNotUndefined(state.afterpay.installments[productSize]) ||
    afterpayAvailable && afterpayBasic.showAfterpay && isNotUndefined(state.afterpay.installments);

  const afterpayAvailability = showInstallments ? {
    ...afterpayBasic,
    installments: {
      summary: state.afterpay.installments[productSize] ? state.afterpay.installments[productSize].summary : state.afterpay.installments.summary,
      showInstallments: state.afterpay.installments[productSize] ? state.afterpay.installments[productSize].show_installments : state.afterpay.installments.show_installments
    }
  } : afterpayBasic;

  return afterpayAvailability;
};

// TODO: SP-10081 Type this properly
export const getWallpaperAfterpayAvailability = (state: any, productSize = 'undefined') => {
  if (isUndefined(state?.afterpay) && isUndefined(state?.afterpay?.available) || isUndefined(state?.afterpay?.show_afterpay) || isUndefined(state?.afterpay?.installments) ||
    isUndefined((state?.afterpay?.installments[productSize]))) {
    return undefined;
  }

  return {
    available: state.afterpay.available, // availability in the country
    eligible: state.afterpay.eligible, // availability on product/cart, also reflects too long turn time
    currencyValid: state.afterpay.currency_valid, // is it an accepted currency?
    showAfterpay: state.afterpay.show_afterpay, // combined available && currencyValid && eligible
    config: {
      currency: state.afterpay.config.currency,
      maxValue: state.afterpay.config.max_value,
      minValue: state.afterpay.config.min_value
    },
    installments: {
      summary: state.afterpay.installments[productSize] ? state.afterpay.installments[productSize].summary : state.afterpay.installments.summary,
      showInstallments: state.afterpay.installments[productSize] ? state.afterpay.installments[productSize].show_installments : state.afterpay.installments.show_installments
    }
  };
};

export const orderItemFromParams = <T extends OrderItemTypes>(state: State): T | undefined => {
  const {
    carts: {pending},
    routing: {locationBeforeTransitions: {query: {order_item_id}}},
  } = state;

  if (isUndefined(order_item_id)) {
    return;
  }

  return getOrderItem<T>(pending, parseInt(order_item_id, 10));
};

export const quantityFromOrderItem = (state: State): number | undefined => {
  const {
    carts: {pending},
    routing: {locationBeforeTransitions: {query: {order_item_id}}},
  } = state;

  if (isUndefined(order_item_id)) {
    return;
  }

  const orderItem = getOrderItem(pending, parseInt(order_item_id, 10));
  const {isFetching, quantity} = orderItem ?? {};
  const {value} = quantity ?? {};

  if (isFetching || isUndefined(value)) {
    return;
  }

  return value;
};

export const getFavoriteCount = (state: State, designId: number): number => {
  const {
    design,
    searchResults: {results = []},
  } = state;
  let favoriteCount = 0;

  if (!isEmpty(results)) {
    const designResult = results.find(({id}) => id === designId);

    if (!isUndefined(designResult)) {
      favoriteCount = designResult.num_favorites;
    }
  } else if (isDesignResponse(design)) {
    favoriteCount = design.favorite_count;
  }

  return favoriteCount;
};

/**
 * Keep trying a function until it succeeds or the timeout interval elapses.
 * The `fn` parameter takes two callbacks for arguments, and should call
 * `successCallback` on success with the success data, or `failureCallback` on
 * failure with the failure data. Failures before final failure will be
 * swallowed. A resolved promise represents final success while a rejected
 * promise represents final failure.
 *
 * @param fn {function(function(T): void, function(E): void)} The function to
 *   retry. Takes a success callback of T => void and failure callback of E =>
 *   void.
 * @param retryMilliseconds {number} How often to retry the function.
 * @param timeoutMilliseconds {number} How long to keep retrying before giving up.
 * @return {Promise<T | E | undefined>} The result from the success or failure
 *   callback, or undefined if the relevant callback doesn't return a value.
 */
export const retryLoop = async <T, E>(
  fn: (success: (data: T) => void, failure: (error: E) => void) => void,
  retryMilliseconds: number,
  timeoutMilliseconds: number,
): Promise<T | E | undefined> => {
  let failureError: typeof UNSET_FLAG | E = UNSET_FLAG;
  const startTime = new Date().getTime();
  let successData: typeof UNSET_FLAG | T = UNSET_FLAG;

  const successCallback = (data: T) => {
    successData = data;
  };
  const failureCallback = (error: E) => {
    failureError = error;
  };

  // Run once before first retry interval
  fn(successCallback, failureCallback);

  while (new Date().getTime() - startTime < timeoutMilliseconds) {
    if (successData !== UNSET_FLAG) {
      return Promise.resolve(successData as T);
    } else {
      await new Promise((resolve) => setTimeout(resolve, retryMilliseconds));
    }

    fn(successCallback, failureCallback);
  }

  return Promise.reject(failureError === UNSET_FLAG ? undefined : failureError as E);
};

/**
 * Represents the state paths that correspond to booleans that track whether a
 * fetch is in process.
 */
const FETCHING_FLAG_PATHS = [
  ['addToCart', 'isFetching'],
  ['addToCart', 'isPostingMeasurements'],
  ['allProducts', 'isFetching'],
  ['crossSell', 'isFetching'],
  ['colorCrossSell', 'isFetching'],
  ['design', 'isFetching'],
  ['fabrics', 'isFetching'],
  ['reviews', 'isFetching'],
  ['stockItems', 'isFetching'],
  ['user', 'isFetching'],
  ['wallpapers', 'isFetching'],
  ['solids', 'isFetching'],
] as const;

/**
 * Given a function and a `getState` function to retrieve current Redux state,
 * call the function if and only if no `isFetching` flags are set in `state`.
 * Returns whatever the function returns if the function is called.
 *
 * This function consults a constant with known `isFetching` flags; if an
 * `isFetching` flag is added to state, it must be added to that object to be
 * seen by this function.
 *
 * @param wrappedFunction {function(): T} The callback function to execute if no known
 *   fetches are occurring.
 * @param getState {function(): object} The function to retrieve live state
 *   (probably `store.getState`).
 * @param successCallback {function(T): void}
 * @param failureCallback {function(): void}
 * @return {void}
 */
export const callIfNothingIsFetching = <T>(
  wrappedFunction: () => T,
  getState: Store['getState'],
  successCallback: (data: T) => void,
  failureCallback: () => void
): void => {
  const somethingIsFetching = FETCHING_FLAG_PATHS.some((path) => get(getState(), path) === true);

  if (!somethingIsFetching) {
    successCallback(wrappedFunction());
  } else {
    failureCallback();
  }
};

export const getColorwayWallpaperImageThumbnail = (designFromState: Design): string => {
  try {
    return isDesignResponse(designFromState) ? designFromState.images.wallpaper[WALLPAPER_GRASSCLOTH].wallpaper_wall_sansoutlet_grass.thumbnail : '';
  } catch (error) {
    return '';
  }
};
